var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { id: "Lists", fluid: "", tag: "section" } },
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                { staticClass: "v-card--material true pa-3 px-5 py-3" },
                [
                  _c(
                    "CardHeading",
                    {
                      attrs: {
                        color: "secondary",
                        loadingTitle: "Loading Black/White",
                        loading: _vm.listLoading,
                      },
                      scopedSlots: _vm._u(
                        [
                          !_vm.listLoading
                            ? {
                                key: "prepend",
                                fn: function () {
                                  return [
                                    _c(
                                      "span",
                                      { staticClass: "d-flex flex-row" },
                                      [
                                        _vm.listMode == "Whitelist"
                                          ? _c(
                                              "v-icon",
                                              {
                                                staticClass:
                                                  "mr-3 primary--text",
                                              },
                                              [_vm._v(" mdi-account-check ")]
                                            )
                                          : _vm._e(),
                                        _vm.listMode == "Blacklist"
                                          ? _c(
                                              "v-icon",
                                              {
                                                staticClass:
                                                  "mr-3 primary--text",
                                              },
                                              [_vm._v(" mdi-account-cancel ")]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "display-2 font-weight-light",
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(_vm.listMode) + " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              }
                            : null,
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c(
                        "span",
                        [
                          _vm._usrFlagsSome({
                            key: "free_games.detail",
                            val: _vm.permissions.EDIT,
                          })
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "mx-1",
                                  attrs: {
                                    elevation: "1",
                                    fab: "",
                                    small: "",
                                    color: `primary`,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.importDialog = true
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", [
                                    _vm._v("mdi-arrow-up-bold-circle"),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._usrFlagsSome({
                            key: "free_games.detail",
                            val: _vm.permissions.EDIT,
                          })
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "mx-1",
                                  attrs: {
                                    elevation: "1",
                                    fab: "",
                                    disabled: _vm.emptyList,
                                    small: "",
                                    color: `primary`,
                                  },
                                  on: { click: _vm.onExportOpen },
                                },
                                [
                                  _c("v-icon", [
                                    _vm._v("mdi-arrow-down-bold-circle"),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._usrFlagsSome({
                            key: "free_games.detail",
                            val: _vm.permissions.EDIT,
                          }) && this.listMode !== `No List`
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "mx-1",
                                  attrs: {
                                    elevation: "1",
                                    fab: "",
                                    small: "",
                                    color: `primary`,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.listEditDialog = true
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("mdi-account-plus")])],
                                1
                              )
                            : _vm._e(),
                          _vm._usrFlagsSome({
                            key: "free_games.detail",
                            val: _vm.permissions.EDIT,
                          }) && this.listMode === `No List`
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "mx-1",
                                  attrs: {
                                    elevation: "1",
                                    fab: "",
                                    small: "",
                                    color: `primary`,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.listCreateDialog = true
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("mdi-plus")])],
                                1
                              )
                            : _vm._e(),
                          _c("confirm-dialog", {
                            attrs: {
                              titleClass: "error",
                              cancelBtnText: "NO",
                              cancelBtnColor: "kajot-text",
                              confirmBtnText: "DELETE",
                              confirmBtnColor: "error",
                            },
                            on: { confirm: _vm.listDelete },
                            scopedSlots: _vm._u([
                              {
                                key: "body",
                                fn: function () {
                                  return [
                                    _vm._v(
                                      " This action will irreversibly delete this list. "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "default",
                                fn: function ({ showConfirm }) {
                                  return [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: {
                                          color: "error darken-2",
                                          bottom: "",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on: tooltip }) {
                                                return [
                                                  _c(
                                                    "span",
                                                    _vm._g({}, tooltip),
                                                    [
                                                      _vm._usrFlagsSome({
                                                        key: "free_games.detail",
                                                        val: _vm.permissions
                                                          .DELETE,
                                                      })
                                                        ? _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "mx-1",
                                                              attrs: {
                                                                elevation: "1",
                                                                fab: "",
                                                                small: "",
                                                                disabled:
                                                                  _vm.emptyList,
                                                                color: `error`,
                                                              },
                                                              on: {
                                                                click:
                                                                  showConfirm,
                                                              },
                                                            },
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  "mdi-delete"
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [_c("span", [_vm._v("Delete")])]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("v-row", {
                    staticClass: "my-2 align-center justify-space-around",
                  }),
                  _c(
                    "v-row",
                    { staticClass: "my-2 align-center justify-space-around" },
                    [
                      _c("v-data-table", {
                        staticClass: "listTable",
                        attrs: {
                          "hide-default-header": "",
                          headers: _vm.headers,
                          items: _vm.aggregatedData,
                        },
                        scopedSlots: _vm._u(
                          [
                            _vm._l(_vm.itemsPerRow, function (i) {
                              return {
                                key: `item.item${i}`,
                                fn: function ({ item }) {
                                  return [
                                    _vm._v(
                                      " " + _vm._s(item[`item${i}`]) + " "
                                    ),
                                  ]
                                },
                              }
                            }),
                            _vm._l(_vm.itemsPerRow, function (i) {
                              return {
                                key: `item.item${i}-actions`,
                                fn: function ({ item }) {
                                  return [
                                    item[`item${i}`] !== ""
                                      ? _c(
                                          "v-tooltip",
                                          {
                                            key: `${_vm.listMode}-${
                                              item[`item${i}`]
                                            }-removeBtn-${i}`,
                                            attrs: {
                                              color: "primary darken-2",
                                              bottom: "",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on, attrs }) {
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "error darken-2",
                                                                size: "22",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.onRemoveItem(
                                                                      item,
                                                                      i
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            "v-icon",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [_vm._v(" mdi-close ")]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "Remove " +
                                                  _vm._s(item[`item${i}`]) +
                                                  " from " +
                                                  _vm._s(_vm.listMode)
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              }
                            }),
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("ImportDialog", {
        ref: "dialog",
        attrs: {
          errorTimeout: 1500,
          title: "Import List",
          width: 500,
          steps: 1,
          useConfirm: _vm.useConfirm,
          parser: "offerPlayers",
          "no-hint": "",
          importDialog: _vm.importDialog,
        },
        on: {
          submit: _vm.onImport,
          "update:importDialog": function ($event) {
            _vm.importDialog = $event
          },
          "update:import-dialog": function ($event) {
            _vm.importDialog = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "actions1-extra",
            fn: function ({ listUpload }) {
              return [
                listUpload
                  ? _c("v-switch", {
                      key: "listSwitchKey",
                      attrs: {
                        inset: "",
                        "prepend-icon": "mdi-account-check",
                        "append-icon": "mdi-account-cancel",
                      },
                      model: {
                        value: _vm.$refs.dialog.tempMode,
                        callback: function ($$v) {
                          _vm.$set(_vm.$refs.dialog, "tempMode", $$v)
                        },
                        expression: "$refs.dialog.tempMode",
                      },
                    })
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          model: {
            value: _vm.exportDialog,
            callback: function ($$v) {
              _vm.exportDialog = $$v
            },
            expression: "exportDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { class: ["primary", "lighten-1", "font-weight-bold"] },
                [
                  _c("span", { staticClass: "text-h5" }, [
                    _vm._v("Export List"),
                  ]),
                ]
              ),
              _c(
                "v-card-text",
                [
                  _c("a", {
                    ref: "exportLink",
                    attrs: {
                      download: `${_vm.filename}.csv`,
                      href: _vm.blobUrl,
                      hidden: "",
                    },
                  }),
                  !_vm.preparingExport
                    ? _c(
                        "v-card",
                        {
                          staticClass:
                            "dropHere d-flex flex-column justify-center align-center",
                          attrs: {
                            ripple: "",
                            color: "menu_background",
                            dense: "",
                          },
                          on: { click: _vm.onClickExport },
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "my-4",
                              class: [_vm.download ? "download" : ""],
                              attrs: { size: "60" },
                            },
                            [_vm._v(" mdi-arrow-down-bold-circle ")]
                          ),
                          _c("p", [
                            _c("b", [_vm._v("CLICK")]),
                            _vm._v(" here to "),
                            _c("b", [_vm._v("EXPORT")]),
                            _vm._v(" list data. "),
                          ]),
                        ],
                        1
                      )
                    : _c(
                        "v-card",
                        {
                          staticClass:
                            "dropHere d-flex flex-column justify-center align-center",
                          attrs: { color: "menu_background" },
                        },
                        [
                          _c("v-progress-circular", {
                            staticClass: "my-4",
                            attrs: {
                              value: _vm.progress,
                              color: "primary",
                              size: "50",
                            },
                          }),
                          _c("p", [_vm._v("Preparing your data")]),
                        ],
                        1
                      ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.exportDialog = false
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          model: {
            value: _vm.listEditDialog,
            callback: function ($$v) {
              _vm.listEditDialog = $$v
            },
            expression: "listEditDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { class: ["primary", "lighten-1", "font-weight-bold"] },
                [
                  _c("span", { staticClass: "text-h5" }, [
                    _vm._v("Add player to " + _vm._s(_vm.listMode)),
                  ]),
                ]
              ),
              _c(
                "v-card-text",
                [
                  _c("v-text-field", {
                    attrs: {
                      autofocus: "",
                      filled: "",
                      required: "",
                      label: "Player",
                    },
                    on: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.onAddItem.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.tempPlayer,
                      callback: function ($$v) {
                        _vm.tempPlayer = $$v
                      },
                      expression: "tempPlayer",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: { click: _vm.cancelListEditDialog },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: !_vm.tempPlayer,
                        color: "primary",
                        text: "",
                      },
                      on: { click: _vm.onAddItem },
                    },
                    [_vm._v(" Submit ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          on: {
            "click:outside": _vm.cancelListCreateDialog,
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              )
                return null
              return _vm.cancelListCreateDialog.apply(null, arguments)
            },
          },
          model: {
            value: _vm.listCreateDialog,
            callback: function ($$v) {
              _vm.listCreateDialog = $$v
            },
            expression: "listCreateDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { class: ["primary", "lighten-1", "font-weight-bold"] },
                [
                  _c("span", { staticClass: "text-h5" }, [
                    _vm._v("Create List"),
                  ]),
                ]
              ),
              _c(
                "v-card-text",
                [
                  [
                    _c(
                      "v-stepper",
                      {
                        staticClass: "menu_background",
                        model: {
                          value: _vm.createListStep,
                          callback: function ($$v) {
                            _vm.createListStep = $$v
                          },
                          expression: "createListStep",
                        },
                      },
                      [
                        _c(
                          "v-stepper-header",
                          [
                            _c(
                              "v-stepper-step",
                              {
                                attrs: {
                                  editable: "",
                                  complete: _vm.createListStep > 1,
                                  step: "1",
                                },
                              },
                              [_vm._v(" List Type ")]
                            ),
                            _c("v-divider"),
                            _c(
                              "v-stepper-step",
                              {
                                attrs: {
                                  editable: "",
                                  complete: _vm.createListStep > 2,
                                  step: "2",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.tempMode ? "Blacklist" : "Whitelist"
                                    ) +
                                    "ed players "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-stepper-items",
                          [
                            _c(
                              "v-stepper-content",
                              { attrs: { step: "2" } },
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "d-flex align-center" },
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "9" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            "error-messages":
                                              _vm.listCreateError,
                                            autofocus: "",
                                            filled: "",
                                            required: "",
                                            label: "Player",
                                          },
                                          on: {
                                            input: function ($event) {
                                              _vm.listCreateError = ""
                                            },
                                            keydown: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              return _vm.createListOnAdd(
                                                _vm.tempPlayer
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.tempPlayer,
                                            callback: function ($$v) {
                                              _vm.tempPlayer = $$v
                                            },
                                            expression: "tempPlayer",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "3", height: "100%" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "mb-7",
                                            attrs: {
                                              shaped: "",
                                              height: "100%",
                                              color: "primary",
                                              text: "",
                                              disabled: !_vm.tempPlayer,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.createListOnAdd(
                                                  _vm.tempPlayer
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" ADD ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("v-data-table", {
                                  ref: "datatbl",
                                  attrs: {
                                    page: _vm.page,
                                    "items-per-page": 6,
                                    headers: [
                                      {
                                        text: `Player ID`,
                                        value: `playerId`,
                                        width: `80%`,
                                      },
                                      {
                                        text: `Actions`,
                                        value: `playerActions`,
                                        width: `20%`,
                                      },
                                    ],
                                    items: _vm.tempPlayers,
                                  },
                                  on: {
                                    "update:page": function ($event) {
                                      _vm.page = $event
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: `item.playerActions`,
                                        fn: function ({ item }) {
                                          return [
                                            _c(
                                              "v-tooltip",
                                              {
                                                key: `${_vm.listMode}-${item.playerId}-removeBtn`,
                                                attrs: {
                                                  color: "primary darken-2",
                                                  bottom: "",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({
                                                        on,
                                                        attrs,
                                                      }) {
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "error darken-2",
                                                                    size: "22",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.onListRemove(
                                                                          item
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                "v-icon",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                " mdi-close "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "Remove " +
                                                      _vm._s(item.playerId)
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                            _c("v-stepper-content", { attrs: { step: "1" } }, [
                              true
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex flex-column align-center mx-6 mt-3",
                                      attrs: { color: "menu_background" },
                                    },
                                    [
                                      _c("v-switch", {
                                        key: "listSwitchKey",
                                        attrs: {
                                          inset: "",
                                          "prepend-icon": "mdi-account-check",
                                          "append-icon": "mdi-account-cancel",
                                        },
                                        model: {
                                          value: _vm.tempMode,
                                          callback: function ($$v) {
                                            _vm.tempMode = $$v
                                          },
                                          expression: "tempMode",
                                        },
                                      }),
                                      _c("p", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.tempMode
                                              ? "Blacklist"
                                              : "Whitelist"
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                ],
                2
              ),
              _c(
                "v-card-actions",
                [
                  _vm.createListStep == 2
                    ? _c(
                        "v-btn",
                        {
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              _vm.createListStep = 1
                            },
                          },
                        },
                        [_vm._v(" Back ")]
                      )
                    : _c(
                        "v-btn",
                        {
                          attrs: { text: "" },
                          on: { click: _vm.cancelListCreateDialog },
                        },
                        [_vm._v(" Cancel ")]
                      ),
                  _c("v-spacer"),
                  _vm.createListStep == 2
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: _vm.tempPlayers.length == 0,
                            color: "primary",
                            text: "",
                          },
                          on: { click: _vm.onCreateList },
                        },
                        [_vm._v(" Submit ")]
                      )
                    : _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", text: "" },
                          on: {
                            click: function ($event) {
                              _vm.createListStep = 2
                            },
                          },
                        },
                        [_vm._v(" Next ")]
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }