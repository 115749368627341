<template>
	<v-container
		id="Lists"
		fluid
		tag="section">
		<v-row dense>
			<v-col>
				<v-card class="v-card--material true pa-3 px-5 py-3">
					<CardHeading
						:color="'secondary'"
						:loadingTitle="'Loading Black/White'"
						:loading="listLoading">
						<template
							v-slot:prepend
							v-if="!listLoading">
							<span class="d-flex flex-row">
								<v-icon
									class="mr-3 primary--text"
									v-if="listMode == 'Whitelist'">
									mdi-account-check
								</v-icon>
								<v-icon
									class="mr-3 primary--text"
									v-if="listMode == 'Blacklist'">
									mdi-account-cancel
								</v-icon>
								<span class="display-2 font-weight-light">
									{{ listMode }}
								</span>
							</span>
						</template>
						<span>
							<v-btn
								v-if="
									_usrFlagsSome({
										key: 'free_games.detail',
										val: permissions.EDIT,
									})
								"
								elevation="1"
								fab
								small
								class="mx-1"
								:color="`primary`"
								@click="importDialog = true">
								<v-icon>mdi-arrow-up-bold-circle</v-icon>
							</v-btn>
							<v-btn
								v-if="
									_usrFlagsSome({
										key: 'free_games.detail',
										val: permissions.EDIT,
									})
								"
								elevation="1"
								fab
								:disabled="emptyList"
								small
								class="mx-1"
								:color="`primary`"
								@click="onExportOpen">
								<v-icon>mdi-arrow-down-bold-circle</v-icon>
							</v-btn>
							<v-btn
								v-if="
									_usrFlagsSome({
										key: 'free_games.detail',
										val: permissions.EDIT,
									}) && this.listMode !== `No List`
								"
								elevation="1"
								fab
								small
								class="mx-1"
								:color="`primary`"
								@click="listEditDialog = true">
								<v-icon>mdi-account-plus</v-icon>
							</v-btn>
							<v-btn
								v-if="
									_usrFlagsSome({
										key: 'free_games.detail',
										val: permissions.EDIT,
									}) && this.listMode === `No List`
								"
								elevation="1"
								fab
								small
								class="mx-1"
								:color="`primary`"
								@click="listCreateDialog = true">
								<v-icon>mdi-plus</v-icon>
							</v-btn>
							<confirm-dialog
								titleClass="error"
								cancelBtnText="NO"
								cancelBtnColor="kajot-text"
								confirmBtnText="DELETE"
								confirmBtnColor="error"
								@confirm="listDelete">
								<template v-slot:body>
									This action will irreversibly delete this list.
								</template>
								<template v-slot:default="{ showConfirm }">
									<v-tooltip
										color="error darken-2"
										bottom>
										<template v-slot:activator="{ on: tooltip }">
											<span v-on="tooltip">
												<v-btn
													v-if="
														_usrFlagsSome({
															key: 'free_games.detail',
															val: permissions.DELETE,
														})
													"
													elevation="1"
													fab
													small
													:disabled="emptyList"
													class="mx-1"
													:color="`error`"
													@click="showConfirm">
													<v-icon>mdi-delete</v-icon>
												</v-btn>
											</span>
										</template>
										<span>Delete</span>
									</v-tooltip>
								</template>
							</confirm-dialog>
						</span>
					</CardHeading>

					<v-row class="my-2 align-center justify-space-around"></v-row>
					<v-row class="my-2 align-center justify-space-around">
						<v-data-table
							class="listTable"
							hide-default-header
							:headers="headers"
							:items="aggregatedData">
							<template
								v-for="i in itemsPerRow"
								v-slot:[`item.item${i}`]="{ item }">
								{{ item[`item${i}`] }}
							</template>
							<template
								v-for="i in itemsPerRow"
								v-slot:[`item.item${i}-actions`]="{ item }">
								<v-tooltip
									:key="`${listMode}-${item[`item${i}`]}-removeBtn-${i}`"
									color="primary darken-2"
									bottom
									v-if="item[`item${i}`] !== ''">
									<template v-slot:activator="{ on, attrs }">
										<v-icon
											v-bind="attrs"
											v-on="on"
											color="error darken-2"
											size="22"
											@click="onRemoveItem(item, i)">
											mdi-close
										</v-icon>
									</template>
									<span>Remove {{ item[`item${i}`] }} from {{ listMode }}</span>
								</v-tooltip>
							</template>
						</v-data-table>
					</v-row>
				</v-card>
			</v-col>
		</v-row>

		<ImportDialog
			ref="dialog"
			:errorTimeout="1500"
			title="Import List"
			:width="500"
			:steps="1"
			:useConfirm="useConfirm"
			parser="offerPlayers"
			@submit="onImport"
			no-hint
			:importDialog.sync="importDialog">
			<template #actions1-extra="{ listUpload }">
				<v-switch
					v-if="listUpload"
					v-model="$refs.dialog.tempMode"
					inset
					key="listSwitchKey"
					prepend-icon="mdi-account-check"
					append-icon="mdi-account-cancel"></v-switch>
			</template>
		</ImportDialog>
		<v-dialog
			v-model="exportDialog"
			max-width="500px">
			<v-card>
				<v-card-title :class="['primary', 'lighten-1', 'font-weight-bold']">
					<span class="text-h5">Export List</span>
				</v-card-title>

				<v-card-text>
					<a
						ref="exportLink"
						:download="`${filename}.csv`"
						:href="blobUrl"
						hidden></a>
					<v-card
						v-if="!preparingExport"
						ripple
						class="dropHere d-flex flex-column justify-center align-center"
						color="menu_background"
						dense
						@click="onClickExport">
						<v-icon
							:class="[download ? 'download' : '']"
							class="my-4"
							size="60">
							mdi-arrow-down-bold-circle
						</v-icon>
						<p>
							<b>CLICK</b>
							here to
							<b>EXPORT</b>
							list data.
						</p>
					</v-card>
					<v-card
						v-else
						color="menu_background"
						class="dropHere d-flex flex-column justify-center align-center">
						<v-progress-circular
							:value="progress"
							color="primary"
							class="my-4"
							size="50"></v-progress-circular>
						<p>Preparing your data</p>
					</v-card>
				</v-card-text>
				<v-card-actions>
					<v-btn
						text
						@click="exportDialog = false">
						Cancel
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog
			v-model="listEditDialog"
			max-width="500px">
			<v-card>
				<v-card-title :class="['primary', 'lighten-1', 'font-weight-bold']">
					<span class="text-h5">Add player to {{ listMode }}</span>
				</v-card-title>
				<!-- <v-tabs class="ma-0" grow>
					<v-tab>Players</v-tab>
					<v-tab>List Mode</v-tab> -->

				<!-- <v-tab-item> -->
				<v-card-text>
					<v-text-field
						autofocus
						@keydown.enter="onAddItem"
						v-model="tempPlayer"
						filled
						required
						label="Player"></v-text-field>
				</v-card-text>
				<!-- </v-tab-item> -->

				<!-- TODO: ASK BE TO MAKE THE STRUCTURES FOR LIST SWITCH -->

				<!-- <v-tab-item> -->
				<!-- <div
					v-if="true"
					color="menu_background"
					class="d-flex flex-column align-center mx-6 mt-3"
				>
					<v-switch
						inset
						key="listSwitchKey"
						prepend-icon="mdi-account-check"
						append-icon="mdi-account-cancel"
						v-model="tempMode"
					></v-switch>
				</div> -->
				<!-- </v-tab-item>
				</v-tabs> -->

				<v-card-actions>
					<v-btn
						text
						@click="cancelListEditDialog">
						Cancel
					</v-btn>
					<v-spacer />
					<v-btn
						:disabled="!tempPlayer"
						color="primary"
						text
						@click="onAddItem">
						Submit
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog
			@click:outside="cancelListCreateDialog"
			@keydown.esc="cancelListCreateDialog"
			v-model="listCreateDialog"
			max-width="500px">
			<v-card>
				<v-card-title :class="['primary', 'lighten-1', 'font-weight-bold']">
					<span class="text-h5">Create List</span>
				</v-card-title>

				<v-card-text>
					<template>
						<v-stepper
							v-model="createListStep"
							class="menu_background">
							<v-stepper-header>
								<v-stepper-step
									editable
									:complete="createListStep > 1"
									step="1">
									List Type
								</v-stepper-step>

								<v-divider></v-divider>

								<v-stepper-step
									editable
									:complete="createListStep > 2"
									step="2">
									{{ tempMode ? "Blacklist" : "Whitelist" }}ed players
								</v-stepper-step>
							</v-stepper-header>

							<v-stepper-items>
								<v-stepper-content step="2">
									<v-row class="d-flex align-center">
										<v-col cols="9">
											<v-text-field
												:error-messages="listCreateError"
												@input="listCreateError = ''"
												autofocus
												@keydown.enter="createListOnAdd(tempPlayer)"
												v-model="tempPlayer"
												filled
												required
												label="Player"></v-text-field>
										</v-col>
										<v-col
											cols="3"
											height="100%">
											<v-btn
												shaped
												class="mb-7"
												height="100%"
												color="primary"
												text
												:disabled="!tempPlayer"
												@click="createListOnAdd(tempPlayer)">
												ADD
											</v-btn>
										</v-col>
									</v-row>

									<v-data-table
										ref="datatbl"
										:page.sync="page"
										:items-per-page="6"
										:headers="[
											{ text: `Player ID`, value: `playerId`, width: `80%` },
											{ text: `Actions`, value: `playerActions`, width: `20%` },
										]"
										:items="tempPlayers">
										<template v-slot:[`item.playerActions`]="{ item }">
											<v-tooltip
												:key="`${listMode}-${item.playerId}-removeBtn`"
												color="primary darken-2"
												bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-icon
														v-bind="attrs"
														v-on="on"
														color="error darken-2"
														size="22"
														@click="onListRemove(item)">
														mdi-close
													</v-icon>
												</template>
												<span>Remove {{ item.playerId }}</span>
											</v-tooltip>
										</template>
									</v-data-table>
								</v-stepper-content>

								<v-stepper-content step="1">
									<div
										v-if="true"
										color="menu_background"
										class="d-flex flex-column align-center mx-6 mt-3">
										<v-switch
											inset
											key="listSwitchKey"
											prepend-icon="mdi-account-check"
											append-icon="mdi-account-cancel"
											v-model="tempMode"></v-switch>
										<p>{{ tempMode ? "Blacklist" : "Whitelist" }}</p>
									</div>
								</v-stepper-content>
							</v-stepper-items>
						</v-stepper>
					</template>
				</v-card-text>

				<!-- TODO: ASK BE TO MAKE THE STRUCTURES FOR LIST SWITCH -->

				<v-card-actions>
					<v-btn
						v-if="createListStep == 2"
						text
						@click="createListStep = 1">
						Back
					</v-btn>
					<v-btn
						v-else
						text
						@click="cancelListCreateDialog">
						Cancel
					</v-btn>
					<v-spacer />

					<v-btn
						v-if="createListStep == 2"
						:disabled="tempPlayers.length == 0"
						color="primary"
						text
						@click="onCreateList">
						Submit
					</v-btn>
					<v-btn
						v-else
						color="primary"
						text
						@click="createListStep = 2">
						Next
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import csvBuilderWorker from "worker-loader!../../script/csvBuilder.worker";
import CardHeading from "../../components/shared/CardHeading.vue";
import ConfirmDialog from "../../components/shared/ConfirmDialog2.vue";
import ImportDialog from "../../components/shared/ImportDialog.vue";
import permissions from "../../mixins/permissions.js";
import table from "../../mixins/table.js";
// import { OfferPlayersCSVParser } from "../../script/parsers/csv/offerListPlayersParser";

export default {
	components: {
		CardHeading,
		ConfirmDialog,
		ImportDialog,
	},
	mixins: [permissions, table],
	model: {
		event: "change",
		prop: "editListConfig",
	},
	props: {
		editListConfig: {
			type: Object,
			default: function () {
				return {};
			},
		},
	},
	data() {
		return {
			download: false,
			page: 1,
			listCreateError: "",
			lastFile: "",
			progress: 0,
			createListStep: 1,
			csvData: null,
			preparingExport: false,
			csvWorker: new csvBuilderWorker(),
			listMode: "",
			bwList: [],
			results: [],
			listLoading: false,
			tempMode: false,
			tempPlayers: [],
			tempPlayer: null,
			exportDialog: false,
			importDialog: false,
			listEditDialog: false,
			listCreateDialog: false,
			offerCode: "",
			noChecksumData: null,
		};
	},
	computed: {
		...mapGetters(["currentCasino"]),
		...mapGetters("breadcrumbs", {
			lastCrumb: "lastCrumb",
			shortCrumbs: "shortCrumbsFromHistory",
		}),
		useConfirm() {
			return this.listMode !== "No List";
		},
		itemsPerRow() {
			switch (this.$vuetify.breakpoint.name) {
				case "xs":
					return 1;
				case "sm":
					return 2;
				case "md":
					return 3;
				case "lg":
					return 5;
			}
			return this.$vuetify.breakpoint.width > 1800 ? 5 : 4;
		},
		filename() {
			const today = new Date();
			return `${this.offerCode}-${this.listMode}-${today.getDate()}_${
				today.getMonth() + 1
			}_${today.getFullYear()}`;
		},
		emptyList() {
			return this.bwList.length == 0;
		},
		headers() {
			return new Array(this.itemsPerRow * 2).fill().map((_, i) => {
				if (i % 2 === 0)
					return {
						text: "Id",
						value: `item${Math.floor(i / 2) + 1}`,
						align: "center",
					};
				return {
					text: "",
					value: `item${Math.floor(i / 2) + 1}-actions`,
					align: "center",
				};
			});
		},
		blobUrl() {
			const csvContent = "data:text/csv;charset=utf-8," + this.csvData;
			return encodeURI(csvContent);
		},
		aggregatedData() {
			const res = [];
			for (let i = 0; i < this.bwList.length; i += this.itemsPerRow) {
				const item = {};
				for (const i2 of [...Array(this.itemsPerRow).keys()]) {
					item[`item${i2 + 1}`] = this.bwList[i + i2] ?? "";
				}
				res.push(item);
			}
			return res;
		},
	},
	methods: {
		...mapActions("freeGames", {
			loadBWList: "loadBWList",
			createBWList: "createBWList",
			deleteBWList: "deleteBWList",
			deleteFromBWList: "deleteFromBWList",
			addToBWList: "addToBWList",
		}),
		onExportOpen() {
			this.exportDialog = true;
			this.preparingExport = true;
			this.csvWorker.postMessage({
				event: "createCSV",
				data: [[this.listMode], ...this.bwList.map((el) => [el])],
				builder: "offerPlayers",
			});
		},
		async onRemoveItem(item, i) {
			await this.deleteFromBWList({
				offerCode: this.offerCode,
				listMode: this.listMode == "Blacklist" ? 1 : 2,
				playerId: item[`item${i}`],
			});
			this.load();
		},
		onListRemove(item) {
			this.tempPlayers.splice(
				this.tempPlayers.findIndex((el) => el.playerId == item.playerId),
				1
			);
		},
		async createListOnAdd(playerId) {
			if (!playerId) return;
			if (this.tempPlayers.findIndex((el) => el.playerId == playerId) > -1) {
				this.listCreateError = "Player ID already in list";
				return;
			}
			this.tempPlayers.push({ playerId });
			this.page = Math.floor(this.tempPlayers.length - 1 / 6) + 1;
			this.tempPlayer = null;
		},
		async onAddItem() {
			if (this.tempPlayer == null || this.tempPlayer == undefined) return;
			await this.addToBWList({
				offerCode: this.offerCode,
				listMode: this.listMode ? 1 : 2,
				playerId: this.tempPlayer,
			});
			this.listEditDialog = false;
			this.tempPlayer = null;
			this.load();
		},
		async onCreateList() {
			await this.createBWList({
				offerCode: this.offerCode,
				listMode: this.tempMode ? 1 : 2,
				players: this.tempPlayers.map((el) => el.playerId),
			});
			this.listCreateDialog = false;
			this.tempPlayer = null;
			this.tempPlayers = [];
			this.createListStep = 1;
			this.load();
		},
		cancelListEditDialog() {
			this.listEditDialog = false;
			this.tempPlayer = null;
		},
		cancelListCreateDialog() {
			this.listCreateDialog = false;
		},
		async listDelete() {
			await this.deleteBWList({
				offerCode: this.offerCode,
				listMode: this.listMode == "Blacklist" ? 1 : 2,
			});
			this.load();
		},
		async onImport(e) {
			await this.createBWList({
				offerCode: this.offerCode,
				listMode: e.mode ? 1 : 2,
				players: e.data,
			});
			this.load();
		},
		onClickExport() {
			this.$refs.exportLink.click();
			this.download = true;
			setTimeout(() => {
				this.download = false;
				this.exportDialog = false;
			}, 1000);
		},
		async load() {
			this.listLoading = true;
			this.offerCode = this.$route.params.offer_id;
			const bwListData = await this.loadBWList({ offerCode: this.offerCode });
			this.bwList = bwListData.players;
			switch (bwListData.list_type) {
				case -1:
					this.listMode = "No List";
					break;
				case 1:
					this.listMode = "Blacklist";
					break;
				case 2:
					this.listMode = "Whitelist";
			}
			this.listLoading = false;
		},
	},
	created() {
		this.csvWorker.addEventListener("message", (e) => {
			if (e.data.status == "progress") {
				this.progress = e.data.value;
			}
			if (e.data.status === "done") {
				this.progress = 100;
				this.csvData = e.data.res;

				setTimeout(() => {
					this.progress = 0;
					this.preparingExport = false;
				}, 750);
			}
		});
		this.load();
	},
	beforeDestroy() {
		this.csvWorker.terminate();
	},
};
</script>
<style>
.listTable {
	min-width: 90%;
}

.download {
	animation: download 1.3s ease-in-out;
}

@keyframes download {
	from {
		transform: translateY(0px);
		opacity: 1;
	}
	to {
		transform: translateY(40px);
		opacity: 0;
	}
}
</style>
